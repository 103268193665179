.form-left {
  background-color: red;
  border-radius: 25px 10px;
  padding: 25px 50px 25px 25px;
  contain: content;
  position: relative;
  left: 25px;
  color: white;
}

.form-right {
  background-color: white;
  border-radius: 10px;
  contain: content;
  padding: 25px 50px;
  margin: 50px 0px;
  position: relative;
  left: -25px;
}

.form-title,
h1,
h2,
h3,
h4,
a {
  line-height: 0.8;
  text-decoration: none;
  color: inherit;
}

.line {
  color: darkgray;
  display: block;
  padding: 15px 0px;
  border-bottom: lightgrey solid 1px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgb(238, 238, 238) 100%
  );
  transition: background 2s;
}
.active {
  color: white;
  /* background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(189, 0, 0, 1) 100%
  ); */
}
.activelink,
.activelink:active {
  color: white;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(189, 0, 0, 1) 100%
  );
}

.line:hover {
  color: white;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(189, 0, 0, 1) 100%
  );
}
.line:active {
  color: white;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(189, 0, 0, 1) 100%
  );
}
a:hover {
  color: white;
}

a:active {
  color: white;
}

.line div {
  margin-left: 15px;
}

.linepush {
  background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(189, 0, 0, 1) 100%
  );
}

.col-12 {
  margin: 0px;
  left: 0px;
  border-radius: 0px;
}
