.__crostudentcontainer {
    height: 100dvh;
}

.__crostudentcontentcontainer {
    overflow-y: auto;
    height: 89dvh;
}

.__crostudenttitlecontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    height: 60px;
    border-bottom: 1px solid grey;
    position: sticky;
    top: 0;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
}

.__crostudentcardcontainer {
    padding: 5px;
    border-radius: 5px;
}

.__crostudentcard {
    border-radius: 5px;
    background: var(--studentphoto) no-repeat center center /cover;
    aspect-ratio: 2/3;
    position: relative;
    cursor: pointer;
}

.__crostudentcarddetail {
    position: absolute;
    bottom: 0px;
    height: 45px;
    opacity: 1;
    padding: 5px;
    border-top: solid 2px red;
    overflow: hidden;
    transition: all 500ms 1s ease-in-out;
}

.__crostudentcarddetail p {
    opacity: 0;
    transition: all 1s ease-in-out;
}

.__crostudentcarddetail .__crostudentcardbadge {
    opacity: 1;
    transition: all 500ms 1s ease-in-out;
}

.__crostudentcarddetail .__crostudentcardbadge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: all 500ms 1s ease-in-out;
}

.__crostudentcardbadgepoint {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.__crostudentcardbadgepoint i {
    margin: 0 0 0 5px;
}

.__crostudentcard:hover .__crostudentcarddetail,
.__crostudentcard:focus .__crostudentcarddetail,
.__crostudentcard:active .__crostudentcarddetail {
    position: absolute;
    bottom: 0px;
    height: 50%;
    opacity: 1;
    background: #ffffffce;
    transition: all 500ms ease-in;
}

.__crostudentcard:hover .__crostudentcarddetail p,
.__crostudentcard:focus .__crostudentcarddetail p,
.__crostudentcard:active .__crostudentcarddetail p {
    opacity: 1;
}

.__crostudentcardtitle {
    padding: 10px;
    line-height: 1;
}

.__crostudentcardtitle p {
    color: white;
    -webkit-filter: invert(100%);
    filter: invert(100%);
}