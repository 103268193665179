.__teacherlistitem {
  padding: 5px 15px 10px 15px;
  border-radius: 5px;
  line-height: 1.2;
  position: relative;
  cursor: pointer;
  transition: all 500ms;
}
.__teacherlistitem:hover {
  background: #e9e9e9;
  transition: all 500ms;
}
.__teacherlistitemactive {
  background: #e9e9e9;
  transition: all 500ms;
}
.__itemarrow {
  opacity: 0;
  position: absolute;
  right: 7px;
  bottom: 5px;
  color: grey;
  transition: all 1s;
}
.__teacherlistitem:hover > .__itemarrow {
  opacity: 1;
  transition: all 1s;
}
.__teacherlistitemactive > .__itemarrow {
  opacity: 1;
  transition: all 1s;
}
.__itembadgecontainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 5px;
  right: 0px;
  opacity: 0.5;
  transition: all 1s;
}
.__teacherlistitem:hover > .__itembadgecontainer {
  right: 25px;
  opacity: 0.1;
  transition: all 1s;
}
.__teacherlistitemactive > .__itembadgecontainer {
  right: 25px;
  opacity: 1;
  transition: all 500ms;
}
.__itembadge {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 3px;
  margin: 0;
  margin-right: 5px;
  text-align: right;
  font-size: 10px;
  font-weight: 700;
  color: white;
  border-radius: 3px;
  width: 35px;
}
