.__top_content {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: #e8e8e8;
  z-index: 100;
}

.__schedule_container {
  position: relative;
  display: block;
  opacity: 0.75;
  transition: all 500ms;
}

.__schedule_container:hover {
  opacity: 1;
}

.__attendance_status {
  position: absolute;
  top: 100px;
  right: -0px;
  color: black;
  font-weight: 500;
  font-size: 40px;
  opacity: 0.2;
}

.__course_status {
  position: absolute;
  top: 5px;
  right: 25px;
  color: black;
  font-weight: 500;
  font-size: 40px;
  opacity: 0.2;
}

.__course_date {
  position: absolute;
  top: 5px;
  width: 40px;
  max-width: 50px;
  line-height: 1;
  text-align: center;
  height: 100%;
  max-height: 60px;
  border-radius: 7px;
  color: rgb(53, 53, 53);
  background-color: white;
  /* border-right: #e8e8e8 solid 1px; */
}

.__course_date:hover {
  color: rgb(53, 53, 53);
  /* border-right: #e8e8e8 solid 1px; */
}

.__course_content {
  margin-left: 60px;
  padding-left: 15px;
}

.__course_content_sg {
  margin-left: 60px;
  padding-left: 35px;
  list-style: none;
}

.__course_content_sg_inset {
  background-color: white;
  padding: 5px 15px;
  border-radius: 0 0 10px 10px;
  margin: 0 5px;
  border: solid 1px lightgray;
}

.__date_day {
  font-size: 28px;
  font-weight: bold;
  background-color: white;
  width: 100%;
  border-radius: 0 0 5px 5px;
}

.__date_month {
  font-size: 100%;
  background-color: lightgray;
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 25px;
}

.__date_year {
  font-size: 90%;
}

.__cs_hour {
  font-size: 24px;
  font-weight: bold;
}

.__student_details_button {
  position: absolute;
  bottom: 15px;
  right: 15px;
  padding: 0px;
}