.blink-1 {
  -webkit-animation: blink-1 3s ease-in-out infinite both;
  animation: blink-1 3s ease-in-out infinite both;
}

.jello-horizontal {
  -webkit-animation: jello-horizontal 1s infinite both;
  animation: jello-horizontal 1s infinite both;
}

@-webkit-keyframes blink-1 {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@keyframes blink-1 {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.__bar-loader {
  height: 10px;
  width: 100%;
  border-radius: 5px;
  outline: solid 2px rgb(255 255 255 /0.3);
  outline-offset: 2px;
  background:
    linear-gradient(#CC0000 0 0),
    linear-gradient(#CC0000 0 0),
    #E4E4ED;
  background-size: 60% 100%;
  background-repeat: no-repeat;
  animation: p6-1 6s infinite;
}

.__custom-loader {

  position: absolute;
  bottom: -35px;
  left: 0;
  width: 25px;
  height: 25px;
  color: #F40000;
  background:
    linear-gradient(currentColor 0 0),
    linear-gradient(currentColor 0 0),
    linear-gradient(currentColor 0 0);
  background-size: 45% 45%;
  background-repeat: no-repeat;
  animation:
    f6-0 1.5s infinite linear alternate,
    f6-0-0 3s infinite linear;
}

.__custom-loader::before {
  content: "";
  display: block;
  width: 50%;
  height: 50%;
  background: currentColor;
  transform: perspective(75px) rotateY(0deg) rotateX(0deg);
  transform-origin: bottom right;
  animation: f6-1 1.5s infinite linear alternate;
}


@keyframes f6-0 {

  0%,
  32.99% {
    background-position: 0 100%, 100% 100%, 100% 0
  }

  33%,
  65.99% {
    background-position: 100% 100%, 100% 100%, 100% 0
  }

  66%,
  100% {
    background-position: 100% 0, 100% 0, 100% 0
  }
}

@keyframes f6-0-0 {

  0%,
  49.99% {
    transform: scaleX(1) rotate(0deg)
  }

  50%,
  100% {
    transform: scaleX(-1) rotate(-90deg)
  }
}


@keyframes f6-1 {
  16.5% {
    transform: perspective(75px) rotateX(-90deg) rotateY(0deg) rotateX(0deg);
    filter: grayscale(0.8)
  }

  33% {
    transform: perspective(75px) rotateX(-180deg) rotateY(0deg) rotateX(0deg);

  }

  66% {
    transform: perspective(75px) rotateX(-180deg) rotateY(-180deg) rotateX(0deg)
  }

  100% {
    transform: perspective(75px) rotateX(-180deg) rotateY(-180deg) rotateX(-180deg);
    filter: grayscale(0.8)
  }
}

@keyframes p6-1 {
  0% {
    background-position: -150% 0, -150% 0
  }

  66% {
    background-position: 250% 0, -150% 0
  }

  100% {
    background-position: 250% 0, 250% 0
  }
}