.__progressicon {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    line-height: 1;
    transition: all 1s;
}

.__description {
    display: none;
    font-size: 10px;
    font-weight: 100;
    transition: all 1s;
    /* z-index: 2000; */
}

.__progressicon:hover {
    background-color: #e9e9e9;
    border: 2px solid #cccccc;
    color: #aaaaaa;
    transition: all 500ms
}

.__progressicon:hover>.__description {
    display: block;
    transition: all 500ms;
}

.__coralcolor {
    background-color: white;
    color: coral;
    border: 2px solid coral;
    transition: all 1s
}

.__greencolor {
    background-color: white;
    color: limegreen;
    border: 2px solid limegreen;
    transition: all 1s
}

.__greycolor {
    background-color: white;
    color: #e9e9e9;
    border: 2px solid #e9e9e9;
    transition: all 1s
}

.__inputgroup {
    margin-Bottom: 16px;
    position: relative
}

.__inputlabel {
    background: white;
    color: #ccc;
    padding: 1px 15px 0 7px;
    position: absolute;
    top: -13px;
    left: 5px;
    font-Style: italic;
    border-radius: 3px;
}