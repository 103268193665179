.star-rating-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.on {
  color: crimson;
  font-size: 15px;
  transition: all 500ms;
}
.off {
  color: rgb(43, 43, 43);
  font-size: 12px;
  transition: all 500ms;
}
