.__staff_menu_container {
  padding: 15px;
}
.__staff_menu {
  font-size: 12px;
  color: #b1b1b1;
  background-color: #f0f0f0;
  padding: 2px 15px;
  font-weight: 300;
  cursor: pointer;
  display: block;
  border-radius: 5px 5px 0 0;
  border: solid gray 1px;
  transition: all 1s;
}
.__staff_menu i {
  padding: 0 7px 0 0;
  transition: all 1s;
}
.__staff_menu:hover {
  color: #272727;
  background-color: white;
  padding: 3px 15px 0px 15px;
  font-weight: 600;
  border: solid gray 1px;
  border-bottom: none;
  transition: all 1s;
}
.__staff_menu_active {
  font-size: 14px;
  color: #272727;
  padding: 5px 15px 0px 15px;
  background-color: white;
  font-weight: 600;
  border-radius: 5px 5px 0 0;
  border: solid gray 1px;
  border-bottom: none;
  transition: all 1s;
}
.__staff_menu_active:hover {
  color: #a50000;
  border: solid gray 1px;
  border-bottom: none;
  transition: all 1s;
}
.__staff_menu_active i {
  padding: 0 7px 0 0;
  transition: all 1s;
}
