.history__container {
  border-radius: 10px;
  margin: 15px 0px;
  padding: 15px;
  background-color: rgb(255, 255, 255);
}

.__btnatt {
  width: 40px;
  height: 40px;
  padding: 5px 3px;
  line-Height: 1;
  margin-Bottom: 5px;
  border: solid 1px #e9e9e9;
  border-Radius: 5px;
  background: var(--bgbtnatt);
  color: var(--clbtnatt);
  cursor: pointer;
  opacity: 1;
  transition: all 1s;
  position: relative;
}

.__btnatt:hover {
  /* border: solid 1px #555; */
  box-shadow: 0 3px 5px -5px rgb(0 0 0 /.5);
  transition: all 500ms;
}

.__btnattnotif {
  position: absolute;
  top: -8px;
  right: 0px;
  width: 40px;
  height: 40px;
  /* border-radius: 15px; */
  /* background: red; */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.__btnattnotif p {
  line-height: 1;
  font-size: 11px;
  font-weight: 900;
  padding: 0;
  margin: 0;
}