.__studentnumbercontainer {
  text-align: center;
  border-radius: 5px;
  width: 25%;
  cursor: pointer;
  transition: all 1s
}

.__studentnumbercontaineractive {
  text-align: center;
  border-radius: 5px;
  width: 25%;
  cursor: pointer;
  color: white;
  box-shadow: 0px 8px 5px -9px white;
  transition: all 1s
}

.__studentnumbercontainer:hover,
.__studentnumbercontainer:focus,
.__studentnumbercontainer:active {
  color: white;
  transition: all 500ms
}

.__studentnumber {
  font-size: 14px;
  font-weight: 700;
}

.__studentcontainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  border: solid white 1px;
  padding: 5px 0;
  cursor: pointer;
  transition: all 500ms;
}

.__studentcontaineractive {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  border: solid white 1px;
  padding: 5px 0;
  background: #e9e9e9;
  border-radius: 5px;
  cursor: pointer;
  transition: all 500ms;
}

.__studentcontaineractive>.__studentdetail {
  padding: 0 5px;
  transition: all 500ms;
}

.__studentcontainer:hover {
  background: #e9e9e9;
  border-radius: 5px;
  transition: all 500ms;
}

.__studentcontainer>.__studentdetail {
  padding: 0;
  transition: all 1s;
}

.__studentcontainer:hover>.__studentdetail {
  padding: 0 5px;
  transition: all 500ms;
}

.__studentcontaineractive>.__btndetail {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background: grey;
  color: white;
  padding: 0 7px;
  border-radius: 5px;
  opacity: 0.7;
  transition: all 500ms;
}

.__studentcontainer>.__btndetail {
  opacity: 0;
  display: none;
  transition: all 1s;
}

.__tagbadge {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  transition: all 500ms;
}

.__tagbadge>div {
  display: flex;
  padding: 2px 7px;
  color: white;
  font-size: 9px;
  font-weight: 900;
  transition: all 500ms;
}