.fc-daygrid-day {
  overflow: auto;
}
.fc-daygrid-event-harness {
  overflow: hidden;
  border: 1px solid rgb(236, 236, 236);
  border-radius: 5px;
  line-height: 1.25;
  padding: 3px;
  cursor: pointer;
}
.fc-timegrid-event-harness {
  cursor: pointer;
}
.fc-list-event {
  cursor: pointer;
}
#calendarEL > .fc > .fc-header-toolbar {
  font-size: 14px;
}
#calendarEL > .fc > .fc-header-toolbar h2 {
  font-size: 16px;
  font-weight: 700;
}
.fc-col-header-cell-cushion {
  font-size: 14px;
  font-weight: 400;
}
#calendarEL {
  min-height: 80vh;
}
